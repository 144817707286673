import React from "react"
import PropTypes from "prop-types"
import Navbar from "../elements/navbar"
import Footer from "../elements/footer"
// import Snowfall from "react-snowfall"

const Layout = ({ children }) => {
  // const [didLoad, setLoad] = useState(false)
  // useEffect(() => {
  //   setLoad(true)
  // }, [])
  return (
    <div>
      {/* {didLoad && <Snowfall />} */}

      <Navbar />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
