import React from "react"
import { injectIntl, changeLocale } from "gatsby-plugin-intl"

// Images
import flag1 from "../images/flag1.png"
import flag2 from "../images/flag2.png"
import flag3 from "../images/flag3.png"
import social1 from "../images/social1.png"
import social2 from "../images/social2.png"
import social3 from "../images/social3.png"
import blueSapphire from "../images/blueSapphire.png"
import payment1 from "../images/payment1.png"
import payment2 from "../images/payment2.png"
import partner1 from "../images/partnerImage1.png"
import partner2 from "../images/partnerImage2.png"
import partner3 from "../images/partnerImage3.png"
import partner4 from "../images/partnerImage4.png"
import nugoIcon from "../images/nugo_icon.png"

import newMacauTiny from "../images/icon.png"

const footer = ({ intl }) => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__header">
          <div className="footer__langs">
            <h5>{intl.formatMessage({ id: "footer_language" })}</h5>
            <img src={flag1} onClick={() => changeLocale("pt")} alt="pt" />
            <img src={flag2} onClick={() => changeLocale("en")} alt="en" />
            <img src={flag3} onClick={() => changeLocale("zh")} alt="zh" />
          </div>
          <span className="footer__divider" />
          <div className="footer__social">
            <h5>{intl.formatMessage({ id: "footer_connect" })}</h5>
            <a href="mailto:info@newmacaucasino.com" target="_top blank">
              <img src={social1} alt="email" />
            </a>
            <a href="https://www.facebook.com/newmacaucasino" target="blank">
              <img src={social2} alt="facebook" />
            </a>
            <a href="https://www.instagram.com/newmacaucasino/" target="blank">
              <img src={social3} alt="instagram" />
            </a>
            <span className="footer__divider" />
          </div>
          
          <div className="footer__message">
            <p>{intl.formatMessage({ id: "footer_message" })}</p>
          </div>
        </div>
      </div>
      <div className="footer__wrapper footer__wrapper2">
        <div className="footer__main">
          <div className="footer__item footer__item1">
            <h5>{intl.formatMessage({ id: "managed_by" })}:</h5>
            <div className="footer__imageContainer">
              <a href="http://www.bluesapphiregaming.com/" target="blank">
                <img
                  className="blueSapphireLogo"
                  src={blueSapphire}
                  alt="blue sapphire"
                />
              </a>
            </div>
          </div>

          <span className="footer__divider2" />

          <div className="footer__item footer__item2">
            <h5>{intl.formatMessage({ id: "payment_providers" })}:</h5>
            <div className="footer__imageContainer">
              <img src={payment1} alt="mastercard" />
              <img src={payment2} alt="visa" />
            </div>
          </div>

          <span className="footer__divider2" />

          <div className="footer__item footer__item3">
            <h5>{intl.formatMessage({ id: "service_providers" })}:</h5>
            <div className="footer__imageContainer">
              <img src={partner1} alt="partners" />
              <img src={partner2} alt="partners" />
              <img src={partner3} alt="partners" />
              <img src={partner4} alt="partners" />
            </div>
          </div>
        </div>
      </div>
      <div className="footer__copyrightContainer">
        <div className="footer__copyright">
          <div className="footer__copyrightItem footer__copyrightItem1">
            <a href="http://www.bluesapphiregaming.com/">
              <img src={newMacauTiny} alt="new macau logo" />
            </a>
          </div>
          <div className="footer__copyrightItem footer__copyrightItem2">
            <p>
              {intl.formatMessage({ id: "copyright" })} ©{" "}
              {new Date().getFullYear()} {intl.formatMessage({ id: "title" })}{" "}
              {intl.formatMessage({ id: "rights_reserved" })}
            </p>
          </div>
          <div className="footer__copyrightItem footer__copyrightItem3">
            <a href="https://www.nugo.xyz" target="blank">
              <img src={nugoIcon} alt="nugo" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default injectIntl(footer)
