import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import { injectIntl, Link } from "gatsby-plugin-intl"

// Images
import logoImage from "../images/logo.png"

import {
  FaHome,
  FaCoins,
  FaUtensils,
  FaBirthdayCake,
  FaWineBottle,
  FaFileInvoice,
  FaNewspaper,
  FaTrophy,
  FaPhone,
} from "react-icons/fa"

const styles = {
  list: {
    width: 250,
  },
  paper: {
    background: "#222",
    top: 0,
  },
}

class TemporaryDrawer extends React.Component {
  state = {
    left: false,
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    })
  }

  render() {
    const { classes, intl } = this.props
    const sideList = (
      <div className="navdrawer">
        <div className="navdrawer__logo">
          <img src={logoImage} alt="logo"/>
        </div>
        <div className={classes.list}>
          <List>
            <Link to="/">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon1">
                    <FaHome />
                  </span>
                  {intl.formatMessage({ id: "home" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/casino">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon2">
                    <FaCoins />
                  </span>
                  {intl.formatMessage({ id: "casino" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/restaurant">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon3">
                    <FaUtensils />
                  </span>
                  {intl.formatMessage({ id: "restaurant" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/events">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon4">
                    <FaBirthdayCake />
                  </span>
                  {intl.formatMessage({ id: "events" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/bar">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon5">
                    <FaWineBottle />
                  </span>
                  {intl.formatMessage({ id: "bar" })}
                </h3>
              </ListItem>
            </Link>
          </List>
          <div className="navdrawer__separator">
            <h5>{intl.formatMessage({ id: "info" })}</h5>
          </div>
          <List>
            <Link to="/terms">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon6">
                    <FaFileInvoice />
                  </span>
                  {intl.formatMessage({ id: "terms" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/privacy">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon7">
                    <FaNewspaper />
                  </span>
                  {intl.formatMessage({ id: "privacy" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/rewards">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon8">
                    <FaTrophy />
                  </span>
                  {intl.formatMessage({ id: "rewards" })}
                </h3>
              </ListItem>
            </Link>

            <Link to="/contact">
              <ListItem button>
                <h3 className="navdrawer__link">
                  <span className="navdrawer__icon navdrawer__icon9">
                    <FaPhone />
                  </span>
                  {intl.formatMessage({ id: "contact" })}
                </h3>
              </ListItem>
            </Link>
          </List>
        </div>
      </div>
    )

    return (
      <div className="hamburgerIcon">
        <div
          id="nav-icon"
          className={this.state.right ? "open" : "close"}
          onClick={this.toggleDrawer("right", true)}
        >
          <span />
          <span />
          <span />
        </div>
        <Drawer
          classes={{ paper: classes.paper }}
          anchor="right"
          open={this.state.right}
          onClose={this.toggleDrawer("right", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("right", false)}
            onKeyDown={this.toggleDrawer("right", false)}
          >
            {sideList}
          </div>
        </Drawer>
      </div>
    )
  }
}

TemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default injectIntl(withStyles(styles)(TemporaryDrawer))
