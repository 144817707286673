import React from "react"
import { injectIntl, Link, changeLocale } from "gatsby-plugin-intl"

// Elements
import Navdrawer from "./navdrawer"

// Images
import logo from "../images/logo.png"
import flag1 from "../images/flag1.png"
import flag2 from "../images/flag2.png"
import flag3 from "../images/flag3.png"
import social1 from "../images/social1.png"
import social2 from "../images/social2.png"
import social3 from "../images/social3.png"

const navbar = ({ intl }) => {
  return (
    <div className="navbar">
      {/* Header Section */}
      <div className="navbar__header">
        <div className="navbar__langs">
          <img src={flag1} onClick={() => changeLocale("pt")} alt="moz" />
          <img src={flag2} onClick={() => changeLocale("en")} alt="eng" />
          <img src={flag3} onClick={() => changeLocale("zh")} alt="zh" />
        </div>

        <div className="navbar__social">
          <h4>{intl.formatMessage({ id: "connect" })}</h4>
          <a href="mailto:info@newmacaucasino.com" target="_top blank">
            <img src={social1} alt="email" />
          </a>
          <a href="https://www.facebook.com/newmacaucasino" target="blank">
            <img src={social2} alt="fb" />
          </a>
          <a href="https://www.instagram.com/newmacaucasino/" target="blank">
            <img src={social3} alt="ig" />
          </a>
        </div>
      </div>
      {/* Main Navigation */}
      <div className="navbar__main">
        <Navdrawer />

        <ul className="navbar__links">
          <Link to="/casino" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "casino" })}</li>
          </Link>
          <Link to="/restaurant" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "restaurant" })}</li>
          </Link>
          <Link to="/events" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "events" })}</li>
          </Link>
          <Link to="/bar" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "bar" })}</li>
          </Link>
        </ul>
        <div className="navbar__logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          <div className="navbar__opening">
            <p>Open 14:00H to 04:00H</p>
          </div>
        </div>
        <ul className="navbar__links">
          <Link to="/terms" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "terms" })}</li>
          </Link>
          <Link to="/privacy" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "privacy" })}</li>
          </Link>
          <Link to="/rewards" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "rewards" })}</li>
          </Link>
          <Link to="/contact" activeStyle={{ color: "#cc9966" }}>
            <li>{intl.formatMessage({ id: "contact" })}</li>
          </Link>
        </ul>
      </div>
    </div>
  )
}

export default injectIntl(navbar)
